import React, { useContext } from "react"
import LanguageContext from "../../contexts/language"
import { getDataFromLink } from "../../utils/text.util"
import Anchor from "../anchor/Anchor"
import HomeSubtitle from "../home-subtitle/HomeSubtitle"
import HomeNewsArticle from "../home-news-article/HomeNewsArticle"
import "./homeNews.scss"

const HomeNews = props => {
  const {
    category,
    title,
    articles,
    newsletter_link,
    more_news_cta_label,
  } = props
  const { urlPrefix } = useContext(LanguageContext)
  const ctaNewsletter = getDataFromLink(newsletter_link?.richText[0])
  const articlesColumn1 = articles.filter(
    article =>
      article?.data?.article_homepage_order === 1 ||
      article?.data?.article_homepage_order === 2
  )
  const articleColumn2 = articles.find(
    article => article?.data?.article_homepage_order === 3
  )
  const articleColumn3 = articles.find(
    article => article?.data?.article_homepage_order === 4
  )

  return (
    <section className="homeNews">
      <div className="homeNews_articles">
        <div className="articlesColumn">
          <HomeSubtitle className="mobileTitle" category={category}>
            {title?.richText[0]}
          </HomeSubtitle>
          {articlesColumn1?.map((article, index) => (
            <HomeNewsArticle
              key={`HomeNewsArticle${index}`}
              article={article?.data}
            />
          ))}
        </div>
        <div className="articlesColumn">
          <HomeNewsArticle article={articleColumn2?.data} />
        </div>
        <div className="articlesColumn">
          <HomeSubtitle className="desktopTitle" category={category}>
            {title?.richText[0]}
          </HomeSubtitle>
          <HomeNewsArticle article={articleColumn3?.data} />
        </div>
      </div>

      <div className="homeNews_links">
        <Anchor
          className="newsLink newsLink__newsletter"
          {...ctaNewsletter?.attributes}
        >
          {ctaNewsletter?.label}
        </Anchor>
        <Anchor className="newsLink" href={`${urlPrefix}/news`}>
          {more_news_cta_label}
        </Anchor>
      </div>
    </section>
  )
}

export default HomeNews
