import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import LanguageContext from "../contexts/language";
import Footer from "../components/footer/Footer";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header/Header";
import Hero from "../components/jobs/hero/Hero";
import Benefits from "../components/productDetail/benefits/Benefits";
import Team from "../components/jobs/team/Team";
import Module from "../components/productDetail/module/Module";
const isBrowser = typeof window !== "undefined";

const Jobs = (props) => {
	const { data } = props.data.prismicJobs;

	const { theme, accesibilitySize } = useContext(LanguageContext);
	const [heightBanner, setHeightBanner] = useState(null);
	const [themeState, setThemeState] = useState(theme);
	const [accesibilitySizeState, setAccesibilitySizeState] =
		useState(accesibilitySize);

	useEffect(() => {
		if (isBrowser && themeState)
			localStorage.setItem("foris_theme", themeState);
	}, [themeState]);

	useEffect(() => {
		if (isBrowser && accesibilitySizeState)
			localStorage.setItem("accesibility_size", accesibilitySizeState);
	}, [accesibilitySizeState]);

	return (
		<Layout
			{...props}
			theme={themeState}
			accesibilitySize={accesibilitySizeState}
		>
			<Seo title={data.seo_title} desc={data.seo_description} />
			<Header />
			<main id="app" className="jobs" role="main">
				<Hero data={data} />
				{data.body.length > 0 &&
					data.body.map((section, bodyIndex) => {
						switch (section.slice_type) {
							case "benefits":
								const benefits = section;
								return (
									<section
										className="jobs_benefits"
										key={`section-${bodyIndex}`}
									>
										<Benefits
											title={benefits.primary.benefit_title}
											subtitle={benefits.primary.benefit_subtitle}
											list={benefits.items}
										/>
									</section>
								);
							case "team":
								const team = section;
								return <Team data={team} key={`section-${bodyIndex}`} />;
							case "modules":
								const module = section;
								return (
									<Module
										title={module.primary.module_title}
										list={module.items}
										key={`section-${bodyIndex}`}
									/>
								);
							default:
								break;
						}
					})}
				<section className="jobs_contact">
					<article className="contactContent">
						<h2 className="contactContent_subtitle">
							{data.job_contact_label.text}
						</h2>
						<a
							className="contactContent_button"
							href={`mailto: ${data.job_contact_email}`}
						>
							{data.job_contact_email}
						</a>
					</article>
				</section>
			</main>
			<Footer />
		</Layout>
	);
};

export default Jobs;

export const pageQuery = graphql`
  query Jobs($lang: String!) {
    prismicJobs(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        pdf_label
        pdf_link {
          url
        }
        side_image {
          alt
          url
        }
        side_video {
          url
        }
        body {
          ... on PrismicJobsDataBodyBenefits {
            id
            slice_type
            primary {
              benefit_title {
                text
              }
              benefit_subtitle
            }
            items {
              benefit_icon {
                alt
                url
              }
              benefit_name
              benefit_description
            }
          }
          ... on PrismicJobsDataBodyTeam {
            id
            slice_type
            primary {
              team_title {
                text
              }
              team_description
              team_image {
                alt
                url
              }
              team_image_mobile {
                alt
                url
              }
            }
          }
          ... on PrismicJobsDataBodyModules {
            id
            slice_type
            primary {
              module_title {
                text
              }
            }
            items {
              submodule_icon {
                alt
                url
              }
              submodule_name
              submodule_link {
                url
              }
            }
          }
        }
        job_contact_label {
          text
        }
        job_contact_email
      }
    }
  }
`;
