import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { graphql } from "gatsby";
import { getTextKeyLabel } from "../utils/text.util";
import LanguageContext from "../contexts/language";
import Footer from "../components/footer/Footer";
import Layout from "../components/layout";
import Header from "../components/header/Header";
import FormContact from "../components/form-contact/FormContact";
import useIsElementVisible from "../hooks/useIsElementVisible";
import NewsHero from "../components/news-hero/NewsHero";
import NewsArticles from "../components/news-articles/NewsArticles";
import "./../styles/news.scss";

const isBrowser = typeof window !== "undefined";

const News = (props) => {
	const { prismicNews, allPrismicNewsArticle } = props.data;
	const data = prismicNews.data;
	const newsArticles = allPrismicNewsArticle?.nodes;

	const { theme, accesibilitySize } = useContext(LanguageContext);
	const [themeState, setThemeState] = useState(theme);
	const [accesibilitySizeState, setAccesibilitySizeState] =
		useState(accesibilitySize);
	const categoriesRef = useRef(null);
	const showScrollButton = !useIsElementVisible(categoriesRef);

	const categories = useMemo(() => {
		const categories = {};

		data?.categories?.forEach((category) => {
			categories[getTextKeyLabel(category?.category_label)] = {
				...category,
				articles: [],
			};
		});

		newsArticles?.forEach((article) => {
			const categoryKey = getTextKeyLabel(article?.data?.article_category);
			categories?.[categoryKey]?.articles.push(article);
		});

		const categoriesArray = Object.values(categories);

		return {
			all: categoriesArray,
			filtered: [...categoriesArray].filter(
				(category) => category.articles.length > 0,
			),
		};
	}, [data?.categories, newsArticles]);

	useEffect(() => {
		if (isBrowser && themeState)
			localStorage.setItem("foris_theme", themeState);
	}, [themeState]);

	useEffect(() => {
		if (isBrowser && accesibilitySizeState)
			localStorage.setItem("accesibility_size", accesibilitySizeState);
	}, [accesibilitySizeState]);

	useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

	const scrollToElement = (id = "") => {
		const element = document.getElementById(id);
		const header = document.querySelector("header.header");

		window.scroll({
			top:
				element.getBoundingClientRect().top +
				window.scrollY -
				header.clientHeight,
			behavior: "smooth",
		});
	};

	return (
		<Layout
			{...props}
			theme={themeState}
			accesibilitySize={accesibilitySizeState}
		>
			<Header />
			<main id="news" className="news">
				<NewsHero
					data={data}
					categories={categories.all}
					categoriesRef={categoriesRef}
					onCategoryClick={(categoryLabel) =>
						scrollToElement(getTextKeyLabel(categoryLabel))
					}
				/>

				<NewsArticles
					filteredCategories={categories?.filtered ?? []}
					scrollToElement={scrollToElement}
					backToCategoriesCta={data?.back_to_categories_cta}
				/>

				<FormContact
					id="contacto"
					category={data?.contact_category}
					question={data?.contact_title}
					ctaText={data.contact_cta}
					description={data?.contact_description}
					uid="contact-news"
				/>

				{showScrollButton && (
					<button
						className="scrollTopButton"
						onClick={() => scrollToElement("news")}
					>
						<img
							src="/img/arrow-up-white-bold.svg"
							alt="Arrow up icon"
							width={24}
							height={24}
							loading="lazy"
						/>
					</button>
				)}
			</main>
			<Footer />
		</Layout>
	);
};

export default News;

export const pageQuery = graphql`
  query News($lang: String!) {
    prismicNews(lang: { eq: $lang }) {
      data {
        title {
          richText
          text
        }
        description {
          richText
          text
        }
        categories {
          category_description
          category_label
          category_title {
            richText
          }
        }
        contact_cta
        contact_category
        contact_description {
          richText
        }
        contact_title {
          richText
        }
        back_to_categories_cta
      }
    }
    allPrismicNewsArticle(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          article_homepage_order
          article_image {
            url
          }
          article_category
          article_title
          article_description
          article_link {
            url
          }
          article_cta_label
        }
      }
    }
  }
`;
