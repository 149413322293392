import React, { useContext, useEffect, useState } from "react"
import cx from "classnames"
import HeaderMobile from "../header-mobile/HeaderMobile"
import LanguageContext from "../../contexts/language"
import "./header.scss"

export const headerItems = [
  {
    type: "link",
    label: "Inicio",
    href: "/",
  },
  {
    type: "dropdown",
    label: "Productos",
    href: "",
    items: [
      {
        label: "Albus",
        href: "/albus",
      },
      {
        label: "Darwin",
        href: "/darwin",
      },
      {
        label: "Stella",
        href: "/stella",
      },
    ],
  },
  {
    type: "link",
    label: "Impacto",
    href: "/news",
  },
  {
    type: "link",
    label: "Contacto",
    href: "#contacto",
  },
  {
    type: "link",
    label: "Careers",
    href: "/careers",
  },
]

const Header = () => {
  const { urlPrefix } = useContext(LanguageContext)
  const [pathName, setPathName] = useState("")

  const isActiveItem = item => {
    if (item?.href === "/") {
      return `${urlPrefix}${pathName}` === `${urlPrefix}${item?.href}`
    } else {
      return `${urlPrefix}${pathName}`?.includes(`${urlPrefix}${item?.href}`)
    }
  }

  useEffect(() => {
    const pathNameNormalize = window.location.pathname.replace("/en", "")
    setPathName(pathNameNormalize)
  }, [])

  return (
    <header className="header">
      <div className="header_content">
        <a className="headerLogo" href={`${urlPrefix}/`}>
          <img
            className="headerLogo_image"
            src="/img/logo-foris.svg"
            alt="Foris"
          />
        </a>
        <nav className="headerNav">
          <ul className="headerNav_list">
            {headerItems?.map((item, index) => {
              switch (item.type) {
                case "link":
                  return (
                    <li className="listItem" key={`listItem${index}`}>
                      <a
                        className={cx(
                          "listItem_link",
                          isActiveItem(item) && "listItem_link__active"
                        )}
                        href={
                          item?.href?.includes("#")
                            ? item?.href
                            : `${urlPrefix}${item?.href}`
                        }
                      >
                        <span className="linkText">{item?.label}</span>
                        <div className="linkUnderline" />
                      </a>
                    </li>
                  )
                case "dropdown":
                  return (
                    <li className="listItem" key={`listItem${index}`}>
                      <div
                        className={cx(
                          "listItem_dropdown",
                          item?.items?.some(subItem => isActiveItem(subItem)) &&
                            "listItem_dropdown__active"
                        )}
                      >
                        <button className="dropdownButton">
                          <span className="dropdownButton_text">
                            {item?.label}
                          </span>
                          <div className="dropdownButton_underline" />
                        </button>
                        <div className="dropdownMenu">
                          {item?.items?.map((subItem, index) => (
                            <a
                              key={`dropdownMenu_item${index}`}
                              className="dropdownMenu_item"
                              href={`${urlPrefix}${subItem?.href}`}
                            >
                              {subItem?.label}
                            </a>
                          ))}
                        </div>
                      </div>
                    </li>
                  )
                default:
                  return <></>
              }
            })}
            <li className="listItem listItem__background">
              <div className="listItem_language">
                <a className="languageText" href={`${pathName}`}>
                  ES
                </a>
                <span className="languageSeparator">/</span>
                <a className="languageText" href={`/en${pathName}`}>
                  EN
                </a>
              </div>
            </li>
          </ul>
          <HeaderMobile className="headerNav_mobile" urlPrefix={urlPrefix} />
        </nav>
      </div>
    </header>
  )
}

export default Header
