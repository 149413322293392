import React, { useContext, useState, useEffect } from "react"
import LanguageContext from "../../contexts/language"
import { headerItems } from "../header/Header"
import "./footer.scss"

const Footer = () => {
  const { urlPrefix, lang } = useContext(LanguageContext)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992)
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const menuLink = headerItems.filter(item => item.label !== "Productos")
  const orderMenu = ["Inicio", "Contacto", "Impacto", "Careers"]
  const reorderedMenuLink = orderMenu.map(label => {
    return menuLink.find(item => item.label === label)
  })

  const productOnlyTitle = headerItems.find(item => item.label === "Productos")
    ?.label
  const productsLinks = headerItems.find(item => item.label === "Productos")
    ?.items
  const orderProducts = ["Darwin", "Albus", "Stella"]
  const reorderedProductsLinks = orderProducts.map(label => {
    return productsLinks.find(item => item.label === label)
  })

  const countriesDesktop = (
    <>
      {lang === "es" ? (
        <>
          <span>Argentina - Chile - Colombia</span>
          <span>Guatemala - España - México</span>
          <span>Paraguay - Perú</span>
        </>
      ) : (
        <>
          <span>Argentina - Chile - Colombia</span>
          <span>Guatemala - Spain - Mexico</span>
          <span>Paraguay - Peru</span>
        </>
      )}
    </>
  )

  const countriesMobile = (
    <>
      {lang === "es" ? (
        <>
          <span>Argentina - Chile - Colombia - España - Guatemala</span>
          <span> México - Paraguay - Perú</span>
        </>
      ) : (
        <>
          <span>Argentina - Chile - Colombia - Spain - Guatemala</span>
          <span> México - Paraguay - Perú</span>
        </>
      )}
    </>
  )

  return (
    <footer className="footer">
      <div className="footer_content">
        <figure className="footerLogo">
          <img
            className="footerLogo_image"
            src="/img/logo-foris-blanco.svg"
          ></img>
        </figure>
        <div className="footerInfo">
          <nav className="footerInfo_nav">
            <ul className="navList">
              {isMobile ? (
                <div className="navList_content">
                  <div className="menu">
                    {reorderedMenuLink?.map((item, itemIndex) => {
                      return (
                        <li
                          className="menu_item"
                          key={`navList_item${itemIndex}`}
                        >
                          <a
                            className="itemLink"
                            href={`${urlPrefix}${item?.href}`}
                          >
                            {item?.label}
                          </a>
                        </li>
                      )
                    })}
                  </div>
                  <p className="subtitle">{productOnlyTitle}</p>
                  <div className="product">
                    {reorderedProductsLinks?.map((item, itemIndex) => {
                      return (
                        <li
                          className="product_item"
                          key={`navList_item${itemIndex}`}
                        >
                          <a
                            className="itemLink"
                            href={`${urlPrefix}${item?.href}`}
                          >
                            {item?.label}
                          </a>
                        </li>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <>
                  {headerItems?.map((item, itemIndex) => {
                    if (item?.items) {
                      return item?.items?.map((subitem, subitemIndex) => (
                        <li
                          className="navList_item"
                          key={`navListSubitem${subitemIndex}`}
                        >
                          <a
                            className="itemLink"
                            href={`${urlPrefix}${subitem?.href}`}
                          >
                            {subitem?.label}
                          </a>
                        </li>
                      ))
                    } else {
                      return (
                        <li
                          className="navList_item"
                          key={`navList_item${itemIndex}`}
                        >
                          <a
                            className="itemLink"
                            href={`${urlPrefix}${item?.href}`}
                          >
                            {item?.label}
                          </a>
                        </li>
                      )
                    }
                  })}
                </>
              )}
            </ul>
          </nav>
          <div className="footerInfo_details">
            <div className="footerContact">
              <div className="footerContact_item">
                <a className="contactInfo" href="mailto:info@foris.ai">
                  <img className="contactInfo_icon" src="/img/mail.svg"></img>
                  info@foris.ai
                </a>
              </div>
            </div>
            <div className="footerCountries">
              {isMobile ? countriesMobile : countriesDesktop}
            </div>
            <div className="footerSocial">
              <p className="footerSocial_title">Social Networks</p>
              <div className="footerSocial_list">
                <a
                  className="item"
                  target="_blank"
                  href="https://twitter.com/foris"
                >
                  <img
                    className="item_icon"
                    src="/img/social-media-twitter.svg"
                  ></img>
                </a>

                <a
                  className="item"
                  target="_blank"
                  href="https://www.linkedin.com/company/foris"
                >
                  <img
                    className="item_icon"
                    src="/img/social-media-in.svg"
                  ></img>
                </a>

                <a
                  className="item"
                  target="_blank"
                  href="https://m.facebook.com/forisai"
                >
                  <img
                    className="item_icon"
                    src="/img/social-media-fb.svg"
                  ></img>
                </a>

                <a
                  className="item"
                  target="_blank"
                  href="https://www.youtube.com/@ForisSantiago"
                >
                  <img
                    className="item_icon"
                    src="/img/social-media-yt.svg"
                  ></img>
                </a>
                <a
                  className="item"
                  target="_blank"
                  href="https://www.instagram.com/foris.ai/"
                >
                  <img
                    className="item_icon"
                    src="/img/social-media-ig.svg"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
